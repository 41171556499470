import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { useAuth } from '@/providers/Auth'
import {
  CreateSignatureMarkRequest,
  CreateSignatureMarkResponse,
  GetListSignatureMarksResponse
} from './useSignaturesApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useSignaturesApi = () => {
  const { authMetadata } = useAuth()
  const { handleApi, queryConfig } = useApi()
  const queryClient = useQueryClient()

  const useGetListSignatureMarks = (
    pageNumber = 0,
    pageSize = 10,
    enabled = true
  ) =>
    useQuery({
      queryKey: [QueryKeys.SignatureMarks, pageNumber, pageSize],
      queryFn: () =>
        handleApi<void, GetListSignatureMarksResponse>(
          `/administration/user/signature-marks?pageNumber=${
            pageNumber + 1
          }&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken && enabled
    })

  const useCreateSignatureMark = () =>
    useMutation({
      mutationFn: (data: CreateSignatureMarkRequest) =>
        handleApi<CreateSignatureMarkRequest, CreateSignatureMarkResponse>(
          `/administration/user/signature-marks`,
          'POST',
          data
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.SignatureMarks]
        })
      }
    })

  const useDeleteSignatureMark = () =>
    useMutation({
      mutationFn: (id: string) =>
        handleApi<void, void>(
          `/administration/user/signature-marks/${id}`,
          'DELETE'
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.SignatureMarks]
        })
      }
    })

  const useGetListRubricMarks = (
    pageNumber = 0,
    pageSize = 10,
    enabled = true
  ) =>
    useQuery({
      queryKey: [QueryKeys.RubricMarks, pageNumber, pageSize],
      queryFn: () =>
        handleApi<void, GetListSignatureMarksResponse>(
          `/administration/user/rubric-marks?pageNumber=${
            pageNumber + 1
          }&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken && enabled
    })

  const useCreateRubricMark = () =>
    useMutation({
      mutationFn: (data: CreateSignatureMarkRequest) =>
        handleApi<CreateSignatureMarkRequest, CreateSignatureMarkResponse>(
          `/administration/user/rubric-marks`,
          'POST',
          data
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.RubricMarks]
        })
      }
    })

  const useDeleteRubricMark = () =>
    useMutation({
      mutationFn: (id: string) =>
        handleApi<void, void>(
          `/administration/user/rubric-marks/${id}`,
          'DELETE'
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.RubricMarks]
        })
      }
    })

  return {
    useGetListSignatureMarks,
    useCreateSignatureMark,
    useDeleteSignatureMark,
    useGetListRubricMarks,
    useCreateRubricMark,
    useDeleteRubricMark
  }
}

export default useSignaturesApi
