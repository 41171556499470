import { RefObject } from 'react'

type ScrollToMarkOptionsType = {
  behavior?: ScrollBehavior
  block?: ScrollLogicalPosition
  inline?: ScrollLogicalPosition
  shakeIntensity?: number
  shakeDuration?: number
  shakeCount?: number
}

const useScrollToMark = () => {
  const scrollToMark = (
    markRef: RefObject<HTMLElement>,
    options: ScrollToMarkOptionsType = {}
  ) => {
    const {
      behavior = 'smooth',
      block = 'center',
      inline = 'center',
      shakeIntensity = 5,
      shakeDuration = 150,
      shakeCount = 4
    } = options

    markRef.current?.scrollIntoView({
      behavior,
      block,
      inline
    })

    if (shakeIntensity > 0) {
      setTimeout(() => {
        for (let i = 0; i < shakeCount; i++) {
          setTimeout(() => {
            window.scrollBy({ top: -shakeIntensity, behavior: 'smooth' })
          }, i * shakeDuration)
          setTimeout(
            () => {
              window.scrollBy({ top: shakeIntensity, behavior: 'smooth' })
            },
            i * shakeDuration + shakeDuration / 2
          )
        }
      }, 0)
    }
  }

  return scrollToMark
}

export default useScrollToMark
