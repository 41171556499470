import { useCallback, useEffect, useState } from 'react'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import Gleap from 'gleap'
import useTranslation from '@/hooks/useTranslation'
import { useViewport } from '@/hooks/useViewport'
import { useLocale } from '@/providers/Locale'
import { IBreakpoint } from '@/hooks/useViewport/useViewport'
import ButtonWithTooltip from '@/ui/molecules/ButtonWithTooltip'

const ToggleChat = () => {
  const { breakpoint } = useViewport()
  const [gleapOpened, setGleapOpened] = useState(true)
  const [firstTouch, setFirstTouch] = useState(true)
  const { t } = useTranslation('toggleChat')
  const { isUpdatingLang } = useLocale()

  const delayAction = (action: () => void, delay = 500) => {
    setTimeout(action, delay)
  }

  const toggleGleap = () => {
    if (isUpdatingLang) {
      delayAction(toggleGleap)
      return
    }
    if (gleapOpened && !firstTouch) closeGleap()
    else openGleap(true)
    setFirstTouch(false)
  }

  const openGleap = useCallback(
    (openChat: boolean) => {
      if (isUpdatingLang) {
        delayAction(() => openGleap(openChat))
        return
      }
      Gleap.showFeedbackButton(true)
      if (openChat) Gleap.open()
      setGleapOpened(true)
    },
    [isUpdatingLang]
  )

  const closeGleap = useCallback(() => {
    if (isUpdatingLang) {
      delayAction(closeGleap)
      return
    }
    Gleap.close()
    Gleap.showFeedbackButton(false)
    setGleapOpened(false)
  }, [isUpdatingLang])

  useEffect(() => {
    if (isUpdatingLang) {
      delayAction(() => {
        if ((breakpoint as IBreakpoint) === 'xs') closeGleap()
        else openGleap(false)
      })
      return
    }
    if ((breakpoint as IBreakpoint) === 'xs') closeGleap()
    else openGleap(false)
  }, [breakpoint, closeGleap, isUpdatingLang, openGleap])

  return (
    <ButtonWithTooltip
      type="button"
      variant="unstyled"
      className="text-gray-400"
      onClick={toggleGleap}
      tooltipText={gleapOpened && !firstTouch ? t?.close : t?.open}
    >
      <span className="sr-only">
        {gleapOpened && !firstTouch ? t?.close : t?.open}
      </span>
      <QuestionMarkCircleIcon className="w-5 h-5" aria-hidden="true" />
    </ButtonWithTooltip>
  )
}

export default ToggleChat
