import { useEffect, useState } from 'react'

export type UseTableResponse = {
  pageNumber: number
  setPageNumber: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  resetPagination: () => void
}

const useTable = (defaultPageSize = 10): UseTableResponse => {
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(defaultPageSize)

  useEffect(() => {
    setPageSize(defaultPageSize)
  }, [defaultPageSize])

  const resetPagination = () => {
    setPageNumber(0)
    setPageSize(defaultPageSize)
  }

  return {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    resetPagination
  }
}

export default useTable
