import React from 'react'
import { ShieldExclamationIcon } from '@heroicons/react/24/outline'
import styles from './SandboxAlert.module.css'
import { cn } from '@/lib/utils'
import Typography from '@/ui/atoms/Typography'

type Props = {
  children: React.ReactNode
  classNameText?: string
  className?: string
}

const SandboxAlert: React.FC<Props> = ({
  children,
  classNameText,
  className
}) => {
  return (
    <div className={cn(styles.sandboxStripe, 'max-h-[35px]', className)}>
      <div className="flex items-center justify-center gap-1 ">
        <ShieldExclamationIcon className="w-5 h-5 text-white md:h-4 md:w-4 dark:text-black" />
        <Typography
          variant="text-xs-medium"
          className={cn('text-white dark:text-black uppercase', classNameText)}
        >
          {children}
        </Typography>
      </div>
    </div>
  )
}

export default SandboxAlert
