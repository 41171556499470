import React from 'react'
import { cn } from '@/lib/utils'

export type IconProps = {
  icon: React.ComponentType<{ className?: string }>
  size?: 'xs' | 'sm' | 'md' | 'lg'
}

const Icon: React.FC<IconProps> = ({ size = 'md', ...props }) => {
  const iconSizeClass = {
    xs: 'h-2 w-2',
    sm: 'h-3 w-3',
    md: 'h-3 lg:h-4 w-3 lg:w-4',
    lg: 'h-4 lg:h-5 w-4 lg:w-5'
  }[size]

  return (
    <props.icon className={cn('shrink-0', iconSizeClass)} aria-hidden="true" />
  )
}

export default Icon
