import { useState, useEffect, useCallback } from 'react'

const useTimer = (initialTime = 60, enabled = false): [number, () => void] => {
  const [time, setTime] = useState<number>(initialTime)

  useEffect(() => {
    if (!enabled || time === 0) return

    const interval = setInterval(() => {
      setTime((prevTime) => prevTime - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [enabled, time])

  const resetTimer = useCallback(() => {
    setTime(initialTime)
  }, [initialTime])

  return [time, resetTimer]
}

export default useTimer
