import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  ArrowDownTrayIcon,
  ChatBubbleLeftEllipsisIcon,
  CheckCircleIcon,
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  ExclamationCircleIcon,
  InboxArrowDownIcon,
  LockClosedIcon,
  MinusCircleIcon,
  XCircleIcon
} from '@heroicons/react/24/outline'
import { useRouter } from 'next/router'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { useScrollToMark } from '@/hooks/useScrollToMark'
import { useSignaturesData } from '@/hooks/useSignaturesData'
import { useTimer } from '@/hooks/useTimer'
import useTranslation from '@/hooks/useTranslation'
import { formatData } from '@/utils/date'
import { SignatureStyle, SignatureType } from '@/types/constants'
import type {
  ApproveEnvelopeDataType,
  DataValidationMFAType,
  DialogResultType,
  ErrorWithResponse,
  IsDownloadingDocumentType,
  OpenActionModalType,
  RecaptchaTokenType,
  ReviewEnvelopeContextType,
  TStatusMFA,
  TStatusMFAKey
} from './ReviewEnvelope.types'
import { MFAStatus, Props } from './ReviewEnvelope.types'
import { Section } from './ReviewEnvelope.types'
import { useAuth } from '../Auth'
import { useLocale } from '../Locale'
import { useSignaturesApi } from '@/hooks/api/administration/profile/user/signatures'
import { useEnvelopeApi } from '@/hooks/api/ecm/useEnvelopeApi'
import { usePublicApi } from '@/hooks/api/public/usePublicApi'
import { SignerStatus } from '@/hooks/api/public/usePublicApi/usePublicApi.types'
import { cn } from '@/lib/utils'
import Avatar from '@/ui/atoms/Avatar'
import Button from '@/ui/atoms/Button'
import { showToast } from '@/ui/atoms/Toast'
import Typography from '@/ui/atoms/Typography'
import { MarkType } from '@/ui/molecules/DocumentSignaturePlacement/DocumentSignaturePlacement.types'
import IconWithTooltip from '@/ui/molecules/IconWithTooltip'
import { Badge } from '@/ui/shadcn/badge'
import * as Popover from '@/ui/shadcn/popover'

const store = createContext<ReviewEnvelopeContextType>(
  {} as ReviewEnvelopeContextType
)
const { Provider } = store

const iconsActionsType: {
  View: JSX.Element
  Approval: JSX.Element
  Rejection: JSX.Element
  CancellationByMfaError: JSX.Element
  ConfirmSignMark: JSX.Element
  EmailMfaRequest: JSX.Element
  SmsMfaRequest: JSX.Element
  Unsubscribe: JSX.Element
} = {
  View: <EnvelopeOpenIcon className="w-4 h-4 text-gray-400" />,
  Approval: <CheckCircleIcon className="w-4 h-4 text-gray-400" />,
  Rejection: <XCircleIcon className="w-4 h-4 text-gray-400" />,
  CancellationByMfaError: (
    <ExclamationCircleIcon className="w-4 h-4 text-gray-400" />
  ),
  ConfirmSignMark: <CheckCircleIcon className="w-4 h-4 text-gray-400" />,
  EmailMfaRequest: <InboxArrowDownIcon className="w-4 h-4 text-gray-400" />,
  SmsMfaRequest: (
    <ChatBubbleLeftEllipsisIcon className="w-4 h-4 text-gray-400" />
  ),
  Unsubscribe: <MinusCircleIcon className="w-4 h-4 text-gray-400" />
}

export function ReviewEnvelopeContext({ children }: Props) {
  const { t, isReady } = useTranslation('reviewEnvelope')

  const { push, query } = useRouter()
  const signatureLinkId = typeof query?.id === 'string' ? query?.id : ''

  const [envelopeReady, setEnvelopeReady] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState<RecaptchaTokenType>({
    preReview: null,
    review: null
  })
  const { usePreReviewEnvelope, useReviewEnvelope } = useEnvelopeApi()
  const { data: dataPreReviewEnvelope, error: errorDataPreReviewEnvelope } =
    usePreReviewEnvelope({
      id: signatureLinkId,
      recaptchaToken: recaptchaToken?.preReview
    })
  const { data: dataReviewEnvelope, isLoading: isLoadingDataReviewEnvelope } =
    useReviewEnvelope(
      {
        id: signatureLinkId,
        recaptchaToken: recaptchaToken?.review
      },
      envelopeReady
    )
  const [currentSection, setCurrentSection] = useState<Section>(
    Section.SignDocument
  )
  const [loadingPdf, setLoadingPdf] = useState(true)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [marks, setMarks] = useState<MarkType[]>([])
  const [activeMark, setActiveMark] = useState<MarkType | null>(null)
  const [openActionModal, setOpenActionModal] = useState<OpenActionModalType>({
    open: true,
    type: 'acceptTerms'
  })
  const [mfaStatus, setMfaStatus] = useState<TStatusMFA>({
    emailCode: MFAStatus.Initial,
    smsCode: MFAStatus.Initial,
    passcode: MFAStatus.Initial
  })
  const [mfaData, setMfaData] = useState<DataValidationMFAType>({
    emailCode: undefined,
    smsCode: undefined,
    passcode: undefined
  })
  const [tourOrderId, setTourOrderId] = useState<number>(0)
  const [approveEnvelopeData, setApproveEnvelopeData] =
    useState<ApproveEnvelopeDataType>({
      signatureMarkId: null,
      signatureMarkBase64: null,
      rubricMarkId: null,
      rubricMarkBase64: null
    })
  const [initialMFASent, setInitialMfaSent] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { isLogged } = useAuth()
  const { useCreateSignatureMark, useCreateRubricMark } = useSignaturesApi()
  const {
    useRejectEnvelope,
    useRequestEmailMFA,
    useRequestSmsMFA,
    useApproveEnvelope
  } = useEnvelopeApi()
  const {
    mutateAsync: mutateAsyncRejectEnvelope,
    isLoading: isLoadingRejectEnvelope
  } = useRejectEnvelope()
  const { mutateAsync: mutateAsyncRequestSmsMFA } = useRequestSmsMFA()
  const {
    mutateAsync: mutateAsyncApproveEnvelope,
    isLoading: isLoadingApproveEnvelope
  } = useApproveEnvelope()
  const { mutateAsync: mutateAsyncRequestEmailMFA } = useRequestEmailMFA()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const canRequestSignatureMarks = useMemo(
    () =>
      Boolean(
        dataReviewEnvelope &&
          dataReviewEnvelope?.signMarks.some(
            (mark: any) => mark.type === SignatureType.Signature
          ) &&
          currentSection === Section.SignDocument &&
          isLogged
      ),
    [currentSection, dataReviewEnvelope, isLogged]
  )
  const canRequestRubricMarks = useMemo(
    () =>
      Boolean(
        dataReviewEnvelope &&
          dataReviewEnvelope?.signMarks.some(
            (mark: any) => mark.type === SignatureType.Rubric
          ) &&
          currentSection === Section.SignDocument &&
          isLogged
      ),
    [currentSection, dataReviewEnvelope, isLogged]
  )
  const {
    dataListSignature,
    dataListRubric,
    tablePaginationRubric,
    tablePaginationSignature
  } = useSignaturesData({
    enabledListRubricMarks: canRequestRubricMarks,
    enabledListSignatureMarks: canRequestSignatureMarks
  })
  const { mutateAsync: createAsyncSignatureMark } = useCreateSignatureMark()
  const { mutateAsync: createAsyncRubricMark } = useCreateRubricMark()
  const {
    useApproveSignMark,
    useDownloadCertificateEnvelope,
    useDownloadDocumentOriginal
  } = usePublicApi()
  const { mutateAsync: mutateAsyncApproveSignMark } = useApproveSignMark()
  const {
    mutateAsync: downloadCertificateEnvelope,
    isLoading: isLoadingDownloadCertificateEnvelope
  } = useDownloadCertificateEnvelope()
  const {
    mutateAsync: downloadDocumentOriginal,
    isLoading: isLoadingDownloadDocumentOriginal
  } = useDownloadDocumentOriginal()
  const scrollToMark = useScrollToMark()
  const { lang } = useLocale()

  const isSandboxEnvelope = useMemo(() => {
    if (dataReviewEnvelope?.id) {
      return dataReviewEnvelope?.isSandbox
    }
    return null
  }, [dataReviewEnvelope?.id, dataReviewEnvelope?.isSandbox])

  const currentSigner = useMemo(() => {
    return dataReviewEnvelope?.signers?.find((signer) => signer.isCurrentSigner)
  }, [dataReviewEnvelope?.signers])

  const [timeUntilResendEmail, resetTimeUntilResendEmail] = useTimer(
    60,
    currentSection === Section.ValidationMFA &&
      currentSigner?.shouldEnforceEmailValidation &&
      !isSandboxEnvelope
  )
  const [timeUntilResendSms, resetTimeUntilResendSms] = useTimer(
    60,
    currentSection === Section.ValidationMFA &&
      currentSigner?.shouldEnforceSmsValidation &&
      !isSandboxEnvelope
  )

  const hasSavedMarks = useMemo(() => {
    return {
      signature: Boolean(dataListSignature?.data?.items?.length),
      rubric: Boolean(dataListRubric?.data?.items?.length)
    }
  }, [
    dataListRubric?.data?.items?.length,
    dataListSignature?.data?.items?.length
  ])

  const isResendDisabled = useMemo(() => {
    return {
      email: Boolean(timeUntilResendEmail > 0 || isSandboxEnvelope),
      sms: Boolean(timeUntilResendSms > 0 || isSandboxEnvelope)
    }
  }, [isSandboxEnvelope, timeUntilResendEmail, timeUntilResendSms])

  const getRecaptchaToken = useCallback(async () => {
    const tokenPreReview = executeRecaptcha
      ? await executeRecaptcha('signer_pre_review')
      : null
    const tokenReview = executeRecaptcha
      ? await executeRecaptcha('signer_review')
      : null
    setRecaptchaToken({
      preReview: tokenPreReview,
      review: tokenReview
    })
  }, [executeRecaptcha])

  useEffect(() => {
    getRecaptchaToken()
  }, [getRecaptchaToken])

  useEffect(() => {
    if (dataPreReviewEnvelope) {
      if (dataPreReviewEnvelope.status === 'Proceed') {
        setEnvelopeReady(true)
      }
      if (dataPreReviewEnvelope.status === 'Redirect') {
        push(`/view/${dataPreReviewEnvelope.envelopeId}`)
      }
      if (dataPreReviewEnvelope.status === 'Editing') {
        setCurrentSection(Section.FeedbackEditing)
      }
    }
    if (errorDataPreReviewEnvelope) {
      const error = errorDataPreReviewEnvelope as ErrorWithResponse
      if (error?.response?.status === 404) {
        showToast.error(t?.toasts?.errorSignature)
        push('/')
      }
    }
  }, [
    dataPreReviewEnvelope,
    errorDataPreReviewEnvelope,
    push,
    t?.toasts?.errorSignature
  ])

  const nextSignMark = useMemo(
    () => marks?.find((mark) => !mark.signature),
    [marks]
  )

  const rejectHandler = useCallback(
    async (data) => {
      try {
        await mutateAsyncRejectEnvelope({
          id: signatureLinkId || '',
          frontendVersion: process.env.NEXT_PUBLIC_FRONTEND_VERSION || '1.0.0',
          ...data
        })
        setCurrentSection(Section.FeedbackRejected)
      } catch (error) {
        console.log(error)
      }
    },
    [mutateAsyncRejectEnvelope, signatureLinkId]
  )

  const validateMFA = useCallback(
    async (recaptchaToken: string) => {
      try {
        await mutateAsyncApproveEnvelope({
          id: signatureLinkId,
          recaptchaToken,
          frontendVersion: process.env.NEXT_PUBLIC_FRONTEND_VERSION || '1.0.0',
          signatureMarkId: approveEnvelopeData.signatureMarkId || undefined,
          rubricMarkId: approveEnvelopeData.rubricMarkId || undefined,
          signatureMarkBase64: approveEnvelopeData.signatureMarkId
            ? undefined
            : approveEnvelopeData.signatureMarkBase64?.split(',')[1],
          rubricMarkBase64: approveEnvelopeData.rubricMarkId
            ? undefined
            : approveEnvelopeData.rubricMarkBase64?.split(',')[1],
          ...mfaData
        })

        setCurrentSection(Section.FeedbackSigned)
      } catch (error: any) {
        if (error?.response?.status === 402) {
          showToast.error(t?.toasts?.errorToken)
        }
        if (error?.response?.status === 451) {
          setCurrentSection(Section.FeedbackFailed)
        }
        showToast.error(t?.toasts?.errorValidateMFA)
      }
    },
    [
      mutateAsyncApproveEnvelope,
      signatureLinkId,
      approveEnvelopeData,
      mfaData,
      t
    ]
  )

  const signHandler = useCallback(async () => {
    if (marks?.every((mark) => mark.signature)) {
      if (
        currentSigner?.shouldEnforceEmailValidation ||
        currentSigner?.shouldEnforceSmsValidation ||
        currentSigner?.shouldEnforcePasscodeValidation
      ) {
        setCurrentSection(Section.ValidationMFA)
      } else {
        const recaptchaToken = executeRecaptcha
          ? await executeRecaptcha('signer_approval')
          : ''

        if (recaptchaToken) {
          validateMFA(recaptchaToken)
        }
      }
    } else {
      showToast.error(t?.toasts?.errorAllMustBeSigned)
      if (nextSignMark) {
        const nextMarkRef = marksRefs.current[nextSignMark.id]
        if (nextMarkRef?.current) {
          nextMarkRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          })
        }
      }
    }
  }, [
    currentSigner?.shouldEnforceEmailValidation,
    currentSigner?.shouldEnforcePasscodeValidation,
    currentSigner?.shouldEnforceSmsValidation,
    executeRecaptcha,
    marks,
    nextSignMark,
    t?.toasts?.errorAllMustBeSigned,
    validateMFA
  ])

  const documents = useMemo(() => {
    if (!dataReviewEnvelope?.documents) return null

    return dataReviewEnvelope?.documents?.sort((a, b) => {
      const indexA = a.index !== undefined ? a.index : Infinity
      const indexB = b.index !== undefined ? b.index : Infinity
      return indexA - indexB
    })
  }, [dataReviewEnvelope?.documents])

  const orderedSignMarks = useMemo(() => {
    if (!documents || !dataReviewEnvelope?.signMarks) return null

    const documentMap = new Map()
    documents.forEach((doc) => {
      documentMap.set(doc.id, doc.index !== undefined ? doc.index : Infinity)
    })

    return dataReviewEnvelope?.signMarks
      ?.sort((a, b) => {
        const docIndexA = documentMap.get(a.documentId)
        const docIndexB = documentMap.get(b.documentId)

        if (docIndexA !== docIndexB) {
          return docIndexA - docIndexB
        }
        if (a.page !== b.page) {
          return a.page - b.page
        }
        if (a.y !== b.y) {
          return a.y - b.y
        }
        return a.x - b.x
      })
      ?.map((mark, index) => ({
        ...mark,
        order: index + 1
      }))
  }, [dataReviewEnvelope?.signMarks, documents])

  useEffect(() => {
    setMarks(orderedSignMarks as MarkType[])
  }, [orderedSignMarks])

  const marksRefs: React.MutableRefObject<{
    [key: string]: React.RefObject<HTMLElement>
  }> = useRef({})

  useEffect(() => {
    if (marks?.length) {
      const newRefs: { [key: string]: React.RefObject<HTMLElement> } = {}

      marks.forEach((mark) => {
        newRefs[mark.id] =
          marksRefs.current[mark.id] || createRef<HTMLElement>()
      })

      marksRefs.current = newRefs
    }
  }, [marks])

  const createMark = useCallback(
    async (dialogResult: DialogResultType, createMarkFn: any) => {
      if (!createMarkFn) return

      const base64Content = dialogResult.signatureImage?.split(',')[1]
      const isSimulatedStyle =
        dialogResult.signatureStyle === SignatureStyle.Simulated &&
        Boolean(dialogResult.fontTypedSignature) &&
        Boolean(dialogResult.signatureText)

      const data = await createMarkFn({
        imageInBase64: base64Content,
        style: isSimulatedStyle
          ? SignatureStyle.Simulated
          : dialogResult.signatureStyle,
        font: isSimulatedStyle ? dialogResult.fontTypedSignature : undefined,
        text: isSimulatedStyle ? dialogResult.signatureText : undefined
      })

      return data?.id
    },
    []
  )

  const onDialogClose = useCallback(
    async (dialogResult: DialogResultType) => {
      if (!dialogResult) return

      if (dialogResult.saveMyProfile) {
        const signatureMarkId = await createMark(
          dialogResult,
          activeMark?.type === SignatureType.Signature
            ? createAsyncSignatureMark
            : null
        )
        const rubricMarkId = await createMark(
          dialogResult,
          activeMark?.type === SignatureType.Rubric
            ? createAsyncRubricMark
            : null
        )

        setApproveEnvelopeData({
          ...approveEnvelopeData,
          ...(signatureMarkId && { signatureMarkId }),
          ...(rubricMarkId && { rubricMarkId })
        })
      }

      const dataIds = {
        rubricMarkId:
          dialogResult.signatureType === SignatureType.Rubric &&
          dialogResult.signatureId
            ? dialogResult.signatureId
            : undefined,
        rubricBase64:
          dialogResult.signatureType === SignatureType.Rubric &&
          dialogResult.signatureImage
            ? dialogResult.signatureImage
            : undefined,
        signatureMarkId:
          dialogResult.signatureType === SignatureType.Signature &&
          dialogResult.signatureId
            ? dialogResult.signatureId
            : undefined,
        signatureBase64:
          dialogResult.signatureType === SignatureType.Signature &&
          dialogResult.signatureImage
            ? dialogResult.signatureImage
            : undefined
      }

      if (
        dataIds.signatureMarkId ||
        dataIds.signatureBase64 ||
        dataIds.rubricMarkId ||
        dataIds.rubricBase64
      ) {
        setApproveEnvelopeData({
          ...approveEnvelopeData,
          ...(dataIds.signatureMarkId && {
            signatureMarkId: dataIds.signatureMarkId
          }),
          ...(dataIds.signatureBase64 && {
            signatureMarkBase64: dataIds.signatureBase64
          }),
          ...(dataIds.rubricMarkId && { rubricMarkId: dataIds.rubricMarkId }),
          ...(dataIds.rubricBase64 && {
            rubricMarkBase64: dataIds.rubricBase64
          })
        })
      }

      setMarks((prevState: any) => {
        return prevState.map((mark: any) => {
          if (mark.id === activeMark?.id) {
            return {
              ...mark,
              signature: {
                signatureType: dialogResult.signatureType,
                signatureStyle: dialogResult.signatureStyle,
                signatureImage: dialogResult.signatureImage,
                signatureTypedText: dialogResult.signatureText,
                fontTypedSignature: dialogResult.fontTypedSignature
              }
            }
          }
          if (mark.signature && mark.type === dialogResult.signatureType) {
            return {
              ...mark,
              signature: {
                ...mark.signature,
                signatureType: dialogResult.signatureType,
                signatureStyle: dialogResult.signatureStyle,
                signatureImage: dialogResult.signatureImage,
                signatureTypedText: dialogResult.signatureText,
                fontTypedSignature: dialogResult.fontTypedSignature
              }
            }
          }

          return mark
        })
      })
      setOpenActionModal({ open: false, type: null })
      setActiveMark(null)

      const recaptchaToken = executeRecaptcha
        ? await executeRecaptcha('signer_signmark_mfa_confirmation')
        : ''

      await mutateAsyncApproveSignMark({
        signatureLinkId: signatureLinkId as string,
        signMarkId: activeMark?.id as string,
        recaptchaToken
      })
    },
    [
      executeRecaptcha,
      mutateAsyncApproveSignMark,
      signatureLinkId,
      activeMark?.id,
      activeMark?.type,
      createMark,
      createAsyncSignatureMark,
      createAsyncRubricMark,
      approveEnvelopeData
    ]
  )

  useEffect(() => {
    if (!marks?.length) return

    if (tourOrderId !== nextSignMark?.order && tourOrderId !== 0) {
      setTourOrderId(nextSignMark?.order as number)
    }

    const el = marksRefs?.current?.[nextSignMark?.id as string]

    if (el?.current) {
      el.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
    }
  }, [tourOrderId, marks, nextSignMark])

  useEffect(() => {
    const initialMFAStatus = {
      emailCode: currentSigner?.shouldEnforceEmailValidation
        ? MFAStatus.Pending
        : MFAStatus.Inserted,
      smsCode: currentSigner?.shouldEnforceSmsValidation
        ? MFAStatus.Pending
        : MFAStatus.Inserted,
      passcode: currentSigner?.shouldEnforcePasscodeValidation
        ? MFAStatus.Pending
        : MFAStatus.Inserted
    }

    setMfaStatus(initialMFAStatus)
  }, [currentSigner])

  const mfaValidationSchema = useMemo(() => {
    if (!currentSigner || !t) return null

    const phoneNumber = formatPhoneNumberIntl(
      `+${String(currentSigner?.phoneIdd)}${currentSigner?.phoneNumber}`
    )
    const passcodeHintValue = currentSigner?.passcodeHint
      ? t?.sectionReviewValidationMfa?.validationSchema?.passcode?.passcodeHint(
          currentSigner?.passcodeHint
        )
      : null

    return {
      emailCode: {
        title:
          t?.sectionReviewValidationMfa?.validationSchema?.emailCode?.title,
        resend: t?.sectionReviewValidationMfa?.validationSchema?.resend,
        sendTo:
          t?.sectionReviewValidationMfa?.validationSchema?.sendTo +
          currentSigner?.email,
        type: 'token',
        isLastItem:
          !currentSigner?.shouldEnforceSmsValidation &&
          !currentSigner?.shouldEnforcePasscodeValidation,
        autoFocus: true,
        tooltipInfo: isSandboxEnvelope
          ? t?.sectionReviewValidationMfa?.validationSchema?.emailCode
              ?.tooltipInfoSandbox
          : t?.sectionReviewValidationMfa?.validationSchema?.emailCode
              ?.tooltipInfo,
        tooltipResent: isSandboxEnvelope
          ? t?.sectionReviewValidationMfa?.validationSchema?.emailCode
              ?.tooltipInfoSandbox
          : t?.sectionReviewValidationMfa?.validationSchema?.emailCode?.tooltipResent(
              timeUntilResendEmail
            ),
        isDisabled: isResendDisabled.email
      },
      smsCode: {
        title: t?.sectionReviewValidationMfa?.validationSchema?.smsCode?.title,
        resend: t?.sectionReviewValidationMfa?.validationSchema?.resend,
        sendTo:
          t?.sectionReviewValidationMfa?.validationSchema?.sendTo + phoneNumber,
        type: 'token',
        isLastItem: !currentSigner?.shouldEnforcePasscodeValidation,
        autoFocus: !currentSigner?.shouldEnforceEmailValidation,
        tooltipInfo: isSandboxEnvelope
          ? t?.sectionReviewValidationMfa?.validationSchema?.smsCode
              ?.tooltipInfoSandbox
          : t?.sectionReviewValidationMfa?.validationSchema?.smsCode
              ?.tooltipInfo,
        tooltipResent: isSandboxEnvelope
          ? t?.sectionReviewValidationMfa?.validationSchema?.smsCode
              ?.tooltipInfoSandbox
          : t?.sectionReviewValidationMfa?.validationSchema?.smsCode?.tooltipResent(
              timeUntilResendSms
            ),
        isDisabled: isResendDisabled.sms
      },
      passcode: {
        title: t?.sectionReviewValidationMfa?.validationSchema?.passcode?.title,
        resend: null,
        type: 'password',
        sendTo: null,
        isLastItem: true,
        autoFocus:
          !currentSigner?.shouldEnforceEmailValidation &&
          !currentSigner?.shouldEnforceSmsValidation,
        tooltipInfo:
          t?.sectionReviewValidationMfa?.validationSchema?.passcode
            ?.tooltipInfo,
        tooltipResent: null,
        passcodeHint: passcodeHintValue
      }
    }
  }, [
    currentSigner,
    isResendDisabled.email,
    isResendDisabled.sms,
    isSandboxEnvelope,
    t,
    timeUntilResendEmail,
    timeUntilResendSms
  ])

  const resendMFA = useCallback(
    async (key: TStatusMFAKey, recaptchaToken: string, isInitial = false) => {
      if (isSandboxEnvelope || (!recaptchaToken && !isInitial)) {
        return
      }

      if (
        (key === 'emailCode' && timeUntilResendEmail < 1) ||
        (key === 'emailCode' && isInitial)
      ) {
        try {
          const extraParams = recaptchaToken ? { recaptchaToken } : {}
          await mutateAsyncRequestEmailMFA({
            id: signatureLinkId as string,
            recaptchaToken,
            ...extraParams
          })
          resetTimeUntilResendEmail()
        } catch (error) {
          console.error(error)
        }
      }
      if (
        (key === 'smsCode' && timeUntilResendSms < 1) ||
        (key === 'smsCode' && isInitial)
      ) {
        try {
          const extraParams = recaptchaToken ? { recaptchaToken } : {}
          await mutateAsyncRequestSmsMFA({
            id: signatureLinkId as string,
            recaptchaToken,
            ...extraParams
          })
          resetTimeUntilResendSms()
        } catch (error) {
          console.error(error)
        }
      }
    },
    [
      isSandboxEnvelope,
      timeUntilResendEmail,
      timeUntilResendSms,
      mutateAsyncRequestEmailMFA,
      signatureLinkId,
      resetTimeUntilResendEmail,
      mutateAsyncRequestSmsMFA,
      resetTimeUntilResendSms
    ]
  )

  const isValidateButtonDisabled = useMemo(() => {
    return (
      mfaStatus.emailCode !== MFAStatus.Inserted ||
      mfaStatus.smsCode !== MFAStatus.Inserted ||
      mfaStatus.passcode !== MFAStatus.Inserted
    )
  }, [mfaStatus])

  const sendInitialMFA = useCallback(async () => {
    if (isSandboxEnvelope) return

    if (currentSigner?.shouldEnforceEmailValidation) {
      const recaptchaToken = executeRecaptcha
        ? await executeRecaptcha('signer_email_mfa_request')
        : ''
      resendMFA('emailCode', recaptchaToken, true)
    }
    if (currentSigner?.shouldEnforceSmsValidation) {
      const recaptchaToken = executeRecaptcha
        ? await executeRecaptcha('signer_sms_mfa_request')
        : ''
      resendMFA('smsCode', recaptchaToken, true)
    }
  }, [
    isSandboxEnvelope,
    currentSigner?.shouldEnforceEmailValidation,
    currentSigner?.shouldEnforceSmsValidation,
    executeRecaptcha,
    resendMFA
  ])

  const currentSignatures = useMemo(() => {
    const signaturesItems = marks?.filter(
      (mark) => mark.signature && mark.type === SignatureType.Signature
    )
    const rubricItems = marks?.filter(
      (mark) => mark.signature && mark.type === SignatureType.Rubric
    )
    const lastSignature = signaturesItems?.[signaturesItems.length - 1]
    const lastRubric = rubricItems?.[rubricItems.length - 1]

    return {
      signature: lastSignature,
      rubric: lastRubric
    }
  }, [marks])

  // const markClickHandler = useCallback(
  //   async (item: MarkType, edit = false) => {
  //     const signature =
  //       item.type === SignatureType.Signature
  //         ? currentSignatures?.signature?.signature
  //         : currentSignatures.rubric?.signature

  //     if (tourOrderId === 0) {
  //       setTourOrderId(nextSignMark?.order || 1)
  //     }

  //     if (!signature || edit) {
  //       setActiveMark(item)

  //       if (item.type === SignatureType.Signature && hasSavedMarks.signature) {
  //         setOpenActionModal({
  //           open: true,
  //           type: 'signatureList'
  //         })
  //         return
  //       }
  //       if (item.type === SignatureType.Rubric && hasSavedMarks.rubric) {
  //         setOpenActionModal({
  //           open: true,
  //           type: 'rubricList'
  //         })
  //         return
  //       }

  //       setOpenActionModal({
  //         open: true,
  //         type: 'createSignature'
  //       })
  //       return
  //     }

  //     setMarks((prevState) => {
  //       return prevState.map((mark) => {
  //         if (mark.id === item.id) {
  //           return {
  //             ...mark,
  //             signature: signature
  //           }
  //         }
  //         return mark
  //       })
  //     })

  //     const recaptchaToken = executeRecaptcha
  //       ? await executeRecaptcha('signer_signmark_mfa_confirmation')
  //       : ''

  //     await mutateAsyncApproveSignMark({
  //       signatureLinkId: signatureLinkId as string,
  //       signMarkId: item?.id as string,
  //       recaptchaToken
  //     })
  //   },
  //   [
  //     currentSignatures?.signature?.signature,
  //     currentSignatures.rubric?.signature,
  //     tourOrderId,
  //     executeRecaptcha,
  //     mutateAsyncApproveSignMark,
  //     signatureLinkId,
  //     nextSignMark?.order,
  //     hasSavedMarks.signature,
  //     hasSavedMarks.rubric
  //   ]
  // )
  const markClickHandler = useCallback(
    async (item: MarkType, edit = false) => {
      const signature =
        item.type === SignatureType.Signature
          ? currentSignatures?.signature?.signature
          : currentSignatures.rubric?.signature

      if (tourOrderId === 0) {
        setTourOrderId(nextSignMark?.order || 1)
      }

      if (!signature || edit) {
        setActiveMark(item)

        if (item.type === SignatureType.Signature && hasSavedMarks.signature) {
          setOpenActionModal({
            open: true,
            type: 'signatureList'
          })
          return
        }
        if (item.type === SignatureType.Rubric && hasSavedMarks.rubric) {
          setOpenActionModal({
            open: true,
            type: 'rubricList'
          })
          return
        }

        setOpenActionModal({
          open: true,
          type: 'createSignature'
        })
        return
      }

      setMarks((prevState) => {
        return prevState.map((mark) => {
          if (mark.id === item.id) {
            return {
              ...mark,
              signature: signature
            }
          }
          return mark
        })
      })

      const recaptchaToken = executeRecaptcha
        ? await executeRecaptcha('signer_signmark_mfa_confirmation')
        : ''

      await mutateAsyncApproveSignMark({
        signatureLinkId: signatureLinkId as string,
        signMarkId: item?.id as string,
        recaptchaToken
      })
    },
    [
      currentSignatures?.signature?.signature,
      currentSignatures.rubric?.signature,
      tourOrderId,
      executeRecaptcha,
      mutateAsyncApproveSignMark,
      signatureLinkId,
      nextSignMark?.order,
      hasSavedMarks.signature,
      hasSavedMarks.rubric
    ]
  )

  const deleteMarkHandler = useCallback(
    (id: string) => {
      setMarks((prevState: any) => {
        return prevState.map((mark: any) => {
          if (mark.id === id) {
            return {
              ...mark,
              signature: null
            }
          }
          return mark
        })
      })
    },
    [setMarks]
  )

  const navigateToMark = useCallback(
    (markId: string) => {
      const markRef = marksRefs.current?.[markId] as any
      if (markRef && markRef.current) {
        scrollToMark(markRef, {
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
          shakeIntensity: 5,
          shakeDuration: 150,
          shakeCount: 4
        })
      }
    },
    [scrollToMark, marksRefs]
  )

  const navigateToNextSignMark = useCallback(() => {
    if (nextSignMark) {
      const nextMarkRef = marksRefs.current[nextSignMark.id]
      if (nextMarkRef?.current) {
        scrollToMark(nextMarkRef, {
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
          shakeIntensity: 0
        })
      }
    }
  }, [nextSignMark, scrollToMark])

  const alreadySigned = useMemo(
    () => Boolean(marks?.find((mark: MarkType) => mark?.signature)),
    [marks]
  )

  useEffect(() => {
    if (
      currentSection === Section.ValidationMFA &&
      !initialMFASent &&
      (currentSigner?.shouldEnforceSmsValidation ||
        currentSigner?.shouldEnforceEmailValidation)
    ) {
      sendInitialMFA()
      setInitialMfaSent(true)
    }
  }, [
    currentSection,
    currentSigner?.shouldEnforceEmailValidation,
    currentSigner?.shouldEnforceSmsValidation,
    sendInitialMFA,
    initialMFASent
  ])

  const handleDownloadDocument = useCallback(
    (
      type: IsDownloadingDocumentType['type'],
      document?: { id: string; name: string }
    ) => {
      if (type === 'certificateEnvelope') {
        downloadCertificateEnvelope(dataReviewEnvelope?.id as string)
      }

      if (type === 'originalDocument' && document) {
        downloadDocumentOriginal({
          id: document?.id,
          name: document?.name?.replace('.pdf', '')
        })
      }
    },
    [
      downloadCertificateEnvelope,
      dataReviewEnvelope?.id,
      downloadDocumentOriginal
    ]
  )

  const isDownloadingDocument: IsDownloadingDocumentType = useMemo(() => {
    return {
      loading:
        isLoadingDownloadCertificateEnvelope ||
        isLoadingDownloadDocumentOriginal,
      type: isLoadingDownloadCertificateEnvelope
        ? 'certificateEnvelope'
        : isLoadingDownloadDocumentOriginal
          ? 'originalDocument'
          : null
    }
  }, [isLoadingDownloadDocumentOriginal, isLoadingDownloadCertificateEnvelope])

  const sectionEnvelopeInfo = useMemo(() => {
    return {
      title: t?.sectionEnvelopeInfo?.title,
      items: [
        {
          title: t?.sectionEnvelopeInfo?.name,
          value: dataReviewEnvelope?.name || ''
        },
        {
          title: t?.sectionEnvelopeInfo?.description,
          value: dataReviewEnvelope?.description || (
            <Typography variant="text-sm-regular" className="text-gray-500">
              -
            </Typography>
          )
        },
        {
          title: t?.sectionEnvelopeInfo?.creation,
          value: dataReviewEnvelope?.createdByName ? (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                name={dataReviewEnvelope?.createdByName as string}
                imgSrc={dataReviewEnvelope?.createdByAvatar}
              />
              <div className="flex flex-col flex-1">
                <Typography
                  variant="text-sm-medium"
                  className="w-full text-gray-500 text-start"
                >
                  {dataReviewEnvelope?.createdByName}
                </Typography>
                {dataReviewEnvelope?.createdAtUtc && (
                  <Typography
                    variant="text-xs-regular"
                    className="text-gray-500 text-start"
                  >
                    {formatData(dataReviewEnvelope?.createdAtUtc, lang)}
                  </Typography>
                )}
              </div>
            </div>
          ) : (
            <Typography variant="text-sm-regular" className="text-gray-500">
              -
            </Typography>
          )
        },
        {
          title: t?.sectionEnvelopeInfo?.lastUpdate,
          value: dataReviewEnvelope?.updatedByName ? (
            <div className="flex items-center gap-1 lg:gap-2">
              <Avatar
                name={dataReviewEnvelope?.updatedByName as string}
                imgSrc={dataReviewEnvelope?.updatedByAvatar}
              />
              <div className="flex flex-col flex-1">
                <Typography
                  variant="text-sm-medium"
                  className="text-gray-500 text-start"
                >
                  {dataReviewEnvelope?.updatedByName}
                </Typography>
                {dataReviewEnvelope?.updatedAtUtc && (
                  <Typography
                    variant="text-xs-regular"
                    className="text-gray-500 text-start"
                  >
                    {formatData(dataReviewEnvelope?.updatedAtUtc, lang)}
                  </Typography>
                )}
              </div>
            </div>
          ) : (
            <Typography variant="text-sm-regular" className="text-gray-500">
              -
            </Typography>
          )
        },
        {
          title: t?.sectionEnvelopeInfo?.expirationDate,
          value: dataReviewEnvelope?.expiresAtUtc ? (
            <Typography
              variant="text-xs-regular"
              className="text-gray-500 text-start"
            >
              {formatData(dataReviewEnvelope?.expiresAtUtc, lang)}
            </Typography>
          ) : (
            <Typography variant="text-sm-regular" className="text-gray-500">
              -
            </Typography>
          )
        }
      ]
    }
  }, [
    dataReviewEnvelope?.createdAtUtc,
    dataReviewEnvelope?.createdByAvatar,
    dataReviewEnvelope?.createdByName,
    dataReviewEnvelope?.description,
    dataReviewEnvelope?.expiresAtUtc,
    dataReviewEnvelope?.updatedAtUtc,
    dataReviewEnvelope?.updatedByAvatar,
    dataReviewEnvelope?.updatedByName,
    dataReviewEnvelope?.name,
    lang,
    t?.sectionEnvelopeInfo?.creation,
    t?.sectionEnvelopeInfo?.description,
    t?.sectionEnvelopeInfo?.expirationDate,
    t?.sectionEnvelopeInfo?.lastUpdate,
    t?.sectionEnvelopeInfo?.name,
    t?.sectionEnvelopeInfo?.title
  ])

  const sectionEnvelopeViewDocuments = useMemo(() => {
    return {
      title: t?.sectionEnvelopeViewDocuments?.title,
      items: documents?.map((document, index) => ({
        title: (
          <div
            className="flex flex-col"
            key={`document-${document.id}-${index}`}
          >
            <Typography variant="text-sm-regular" className="text-gray-500">
              {document.name}
            </Typography>
            {document?.description && (
              <Typography variant="text-sm-regular" className="text-gray-500">
                {document.description}
              </Typography>
            )}
          </div>
        ),
        value: (
          <Popover.Popover>
            <Popover.PopoverTrigger asChild>
              <div>
                <Button
                  variant="outlineSecondary"
                  size="sm"
                  className="flex items-center gap-1"
                >
                  <ArrowDownTrayIcon className="w-4 h-4" />
                  {t?.sectionEnvelopeViewDocuments?.buttonDownloadDocument}
                </Button>
              </div>
            </Popover.PopoverTrigger>
            <Popover.PopoverContent className="dark:border-gray-300 w-72">
              <div className="flex flex-col gap-4 p-1">
                {t?.sectionEnvelopeViewDocuments?.downloadButtonList?.map(
                  (button: any, index: any) => (
                    <Button
                      key={`button-${button.label}-${index}`}
                      variant="secondary"
                      className="w-full"
                      size="sm"
                      loading={
                        isDownloadingDocument.type === button?.type &&
                        isDownloadingDocument.loading
                      }
                      onClick={() => {
                        if (button.type === 'certificateEnvelope') {
                          handleDownloadDocument(button?.type)
                          return
                        }
                        handleDownloadDocument(button?.type, document)
                      }}
                    >
                      <ArrowDownTrayIcon className="w-4 h-4" />
                      {button.label}
                    </Button>
                  )
                )}
              </div>
            </Popover.PopoverContent>
          </Popover.Popover>
        )
      })),
      emptyState: dataReviewEnvelope?.documents?.length === 0,
      emptyStateTitle: t?.sectionEnvelopeViewDocuments?.emptyState?.title,
      emptyStateMessage: t?.sectionEnvelopeViewDocuments?.emptyState?.message
    }
  }, [
    dataReviewEnvelope?.documents?.length,
    documents,
    handleDownloadDocument,
    isDownloadingDocument.loading,
    isDownloadingDocument.type,
    t?.sectionEnvelopeViewDocuments?.buttonDownloadDocument,
    t?.sectionEnvelopeViewDocuments?.downloadButtonList,
    t?.sectionEnvelopeViewDocuments?.emptyState?.message,
    t?.sectionEnvelopeViewDocuments?.emptyState?.title,
    t?.sectionEnvelopeViewDocuments?.title
  ])

  const sectionEnvelopeSigners = useMemo(() => {
    return {
      title: (
        <div className="flex items-center gap-1 px-4 py-3">
          <Typography variant="text-base-medium">
            {t?.sectionEnvelopeSigners?.title}
          </Typography>
          <Badge
            size="xs"
            className={t?.sectionEnvelopeSigners?.badgeSignedOrder?.className}
          >
            {t?.sectionEnvelopeSigners?.badgeSignedOrder?.label(
              dataReviewEnvelope?.signInOrder
            )}
          </Badge>
        </div>
      ),
      items: dataReviewEnvelope?.signers?.map((signer, index) => {
        const statusEnvelope =
          t?.sectionEnvelopeSigners?.status?.[signer?.status]
        return {
          title: (
            <div key={`signer-${signer.id}-${index}`} className="flex flex-col">
              <Typography variant="text-base-medium" className="text-gray-800">
                {signer.name}
              </Typography>
              <Typography variant="text-sm-regular" className="text-gray-800">
                {signer.email}
              </Typography>
            </div>
          ),
          value: (
            <>
              <div className="relative flex justify-center w-full h-6 sm:hidden">
                <div className="flex items-center self-center gap-3">
                  <IconWithTooltip
                    icon={
                      <LockClosedIcon
                        className={`h-4 w-4 ${
                          signer.shouldEnforcePasscodeValidation
                            ? 'text-success-600'
                            : 'text-gray-400'
                        }`}
                      />
                    }
                    tooltipText={
                      t?.sectionEnvelopeSigners?.tooltips
                        ?.shouldEnforcePasscodeValidation
                    }
                  />
                  <IconWithTooltip
                    icon={
                      <EnvelopeIcon
                        className={`h-4 w-4 ${
                          signer.shouldEnforceEmailValidation
                            ? 'text-success-600'
                            : 'text-gray-400'
                        }`}
                      />
                    }
                    tooltipText={
                      t?.sectionEnvelopeSigners?.tooltips
                        ?.shouldEnforceEmailValidation
                    }
                  />
                  <IconWithTooltip
                    icon={
                      <DevicePhoneMobileIcon
                        className={`h-4 w-4 ${
                          signer.shouldEnforceSmsValidation
                            ? 'text-success-600'
                            : 'text-gray-400'
                        }`}
                      />
                    }
                    tooltipText={
                      t?.sectionEnvelopeSigners?.tooltips
                        ?.shouldEnforceSmsValidation
                    }
                  />
                </div>
                {signer?.status !== SignerStatus.None && (
                  <Badge
                    size="sm"
                    className={cn(
                      'absolute right-0',
                      statusEnvelope?.className
                    )}
                  >
                    {statusEnvelope?.label}
                  </Badge>
                )}
              </div>
              <div className="hidden gap-4 sm:flex">
                <div className="flex items-center gap-3">
                  <IconWithTooltip
                    icon={
                      <LockClosedIcon
                        className={`h-4 w-4 ${
                          signer.shouldEnforcePasscodeValidation
                            ? 'text-success-600'
                            : 'text-gray-400'
                        }`}
                      />
                    }
                    tooltipText={
                      t?.sectionEnvelopeSigners?.tooltips
                        ?.shouldEnforcePasscodeValidation
                    }
                  />
                  <IconWithTooltip
                    icon={
                      <EnvelopeIcon
                        className={`h-4 w-4 ${
                          signer.shouldEnforceEmailValidation
                            ? 'text-success-600'
                            : 'text-gray-400'
                        }`}
                      />
                    }
                    tooltipText={
                      t?.sectionEnvelopeSigners?.tooltips
                        ?.shouldEnforceEmailValidation
                    }
                  />
                  <IconWithTooltip
                    icon={
                      <DevicePhoneMobileIcon
                        className={`h-4 w-4 ${
                          signer.shouldEnforceSmsValidation
                            ? 'text-success-600'
                            : 'text-gray-400'
                        }`}
                      />
                    }
                    tooltipText={
                      t?.sectionEnvelopeSigners?.tooltips
                        ?.shouldEnforceSmsValidation
                    }
                  />
                </div>
                {signer?.status !== SignerStatus.None && (
                  <Badge size="sm" className={statusEnvelope?.className}>
                    {statusEnvelope?.label}
                  </Badge>
                )}
              </div>
            </>
          )
        }
      }),
      emptyState: dataReviewEnvelope?.signers?.length === 0,
      emptyStateTitle: t?.sectionEnvelopeSigners?.emptyState?.title,
      emptyStateMessage: t?.sectionEnvelopeSigners?.emptyState?.message
    }
  }, [
    dataReviewEnvelope?.signInOrder,
    dataReviewEnvelope?.signers,
    t?.sectionEnvelopeSigners?.badgeSignedOrder,
    t?.sectionEnvelopeSigners?.emptyState,
    t?.sectionEnvelopeSigners?.status,
    t?.sectionEnvelopeSigners?.title,
    t?.sectionEnvelopeSigners?.tooltips?.shouldEnforceEmailValidation,
    t?.sectionEnvelopeSigners?.tooltips?.shouldEnforcePasscodeValidation,
    t?.sectionEnvelopeSigners?.tooltips?.shouldEnforceSmsValidation
  ])

  const sectionEnvelopeTimeline = useMemo(() => {
    const dataActions = dataReviewEnvelope?.actions
      ?.map((action) => {
        const currentSigner = dataReviewEnvelope?.signers?.find(
          (signer) => signer.isCurrentSigner
        )
        return {
          user: currentSigner?.name || '',
          action:
            t?.sectionEnvelopeActivity?.actionsTypeLabel?.[action.type]?.(
              action.ip,
              action.location
            ) || '',
          time: formatData(action.createdAtUtc, lang),
          icon:
            iconsActionsType[action.type as keyof typeof iconsActionsType] ||
            null
        }
      })
      ?.sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
    return {
      title: t?.sectionEnvelopeActivity?.title,
      items: dataActions || [],
      emptyState: dataActions?.length === 0,
      emptyStateTitle: t?.sectionEnvelopeActivity?.emptyState?.title,
      emptyStateMessage: t?.sectionEnvelopeActivity?.emptyState?.message
    }
  }, [
    dataReviewEnvelope?.actions,
    dataReviewEnvelope?.signers,
    lang,
    t?.sectionEnvelopeActivity?.actionsTypeLabel,
    t?.sectionEnvelopeActivity?.emptyState?.message,
    t?.sectionEnvelopeActivity?.emptyState?.title,
    t?.sectionEnvelopeActivity?.title
  ])

  const isLoadingSkeleton = useMemo(
    () => isLoadingDataReviewEnvelope || !isReady,
    [isLoadingDataReviewEnvelope, isReady]
  )

  return (
    <Provider
      value={{
        dataReviewEnvelope,
        currentSection,
        setCurrentSection,
        loadingPdf,
        setLoadingPdf,
        acceptTerms,
        setAcceptTerms,
        marks,
        activeMark,
        openActionModal,
        setOpenActionModal,
        rejectHandler,
        signHandler,
        onDialogClose,
        marksRefs,
        mfaValidationSchema,
        resendMFA,
        validateMFA,
        isValidateButtonDisabled,
        currentSigner,
        mfaData,
        setMfaData,
        setMfaStatus,
        tourOrderId,
        setTourOrderId,
        nextSignMark,
        sendInitialMFA,
        isLoadingApproveEnvelope,
        isLoadingDataReviewEnvelope,
        markClickHandler,
        deleteMarkHandler,
        navigateToMark,
        alreadySigned,
        isSandboxEnvelope,
        isLoadingSkeleton,
        sectionEnvelopeInfo,
        sectionEnvelopeViewDocuments,
        sectionEnvelopeSigners,
        sectionEnvelopeTimeline,
        navigateToNextSignMark,
        documents,
        isDrawerOpen,
        setIsDrawerOpen,
        isLoadingRejectEnvelope,
        dataListSignature,
        dataListRubric,
        tablePaginationRubric,
        tablePaginationSignature
      }}
    >
      {children}
    </Provider>
  )
}

const useReviewEnvelope = () => useContext(store)

export { ReviewEnvelopeContextType, useReviewEnvelope }
