import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { useAuth } from '@/providers/Auth'
import { GetListNotificationsResponse } from './useNotificationsApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useNotificationsApi = () => {
  const { handleApi, queryConfig } = useApi()
  const { authMetadata } = useAuth()
  const queryClient = useQueryClient()

  const useGetListNotifications = () =>
    useQuery({
      queryKey: [QueryKeys.ListNotifications],
      queryFn: () =>
        handleApi<void, GetListNotificationsResponse>(
          '/platform/notifications',
          'GET'
        ),
      ...queryConfig,
      refetchInterval: 60000,
      enabled: !!authMetadata?.accessToken
    })

  const useMarkAsRead = () =>
    useMutation({
      mutationFn: ({ id }: { id: string }) =>
        handleApi<void, void>(`/platform/notifications/${id}`, 'POST'),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ListNotifications]
        })
      }
    })

  return {
    useGetListNotifications,
    useMarkAsRead
  }
}

export default useNotificationsApi
