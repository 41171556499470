export enum AccountType {
  Company = 'Company',
  Personal = 'Personal'
}

export enum Currency {
  USD = 'USD',
  BRL = 'BRL'
}

export enum PlanRecurrence {
  Monthly = 'Monthly',
  Yearly = 'Yearly'
}

export enum SignatureType {
  Rubric = 'Rubric',
  Signature = 'Signature'
}

export enum SignatureStyle {
  Draw = 'Draw',
  Simulated = 'Simulated',
  Upload = 'Upload'
}
