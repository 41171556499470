import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@/lib/utils'
import Icon, { IconProps } from '@/ui/atoms/Icon'
import IconWithTooltip from '@/ui/molecules/IconWithTooltip'

const badgeVariants = cva(
  'flex gap-1 items-center w-fit border font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground shadow',
        secondary: 'border-transparent bg-secondary text-secondary-foreground',
        outlineSecondary: 'border-secondary bg-transparent text-secondary',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground shadow',
        outline: 'text-foreground',
        success: 'border-transparent shadow bg-green-100 text-success-600',
        info: 'border-transparent shadow bg-blue-100 text-info-600',
        warning: 'border-transparent shadow bg-yellow-100 text-warning-600',
        error: 'border-transparent shadow bg-red-100 text-error-600',
        neutral:
          'border border-gray-400 bg-background text-gray-700 disabled:hover:bg-background disabled:hover:text-gray-700'
      },
      size: {
        xs: 'rounded-md text-xs px-1.5 py-0.5',
        sm: 'rounded-md text-xs px-2 py-1',
        md: 'rounded-md text-sm px-2 lg:px-3 py-1 lg:py-2',
        lg: 'rounded-lg text-base lg:text-lg px-3 lg:px-4 py-2 lg:py-3',
        xl: 'rounded-lg text-lg lg:text-xl px-4 lg:px-5 py-3 lg:py-4'
      },
      shape: {
        square: 'rounded-md',
        round: 'rounded-full'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
      shape: 'square'
    }
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  icon?: React.ComponentType<{ className?: string }>
  tooltip?: string
}

const Badge = ({
  className,
  variant,
  size,
  shape,
  tooltip = '',
  icon,
  children,
  ...props
}: BadgeProps) => (
  <div
    className={cn(badgeVariants({ variant, size, shape }), className)}
    {...props}
  >
    {children}
    {icon && tooltip && (
      <IconWithTooltip
        icon={<Icon icon={icon} size={size as IconProps['size']} />}
        tooltipText={tooltip}
      />
    )}
  </div>
)

export { Badge, badgeVariants }
